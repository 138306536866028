import React from "react"
import { Card } from "@fastly/consistently-vanilla"
import LabelList from "~components/label-list/label-list"
import isClientSide from "~utils/is-client-side"
import { prefixPath } from "~utils/path"
import * as styles from "./card-gallery.module.css"

const slugify = require("slugify")

const CardGallery = ({ data, labelFilter, noShadow = false }) => {
  const queryParams = labelFilter && isClientSide() ? new URL(window.location.href).searchParams : new URLSearchParams()
  const filterVal = labelFilter && queryParams.get(labelFilter.queryParam)
  const filterFn = (n) => !filterVal || (n.labels && n.labels.map((l) => slugify(l.toLowerCase())).includes(filterVal))
  const labelPrefix = labelFilter ? prefixPath(labelFilter.url) + "?" + labelFilter.queryParam + "=" : undefined

  return (
    <div className={styles.gallery}>
      {data.filter(filterFn).map((cardData) => (
        <div key={cardData.link} className={styles.entry}>
          <Card
            noShadow={noShadow}
            meta={
              "labels" in cardData ? (
                <LabelList
                  textList
                  withBackground={false}
                  linkPrefix={labelPrefix}
                  labels={Array.isArray(cardData.labels) ? cardData.labels : [cardData.labels]}
                />
              ) : null
            }
            link={prefixPath(cardData.link)}
            title={cardData.title}
            footer={
              "footerElements" in cardData ? (
                <div className={styles.footerElements}>{cardData.footerElements}</div>
              ) : null
            }
            image={cardData.image}
            background={noShadow && "grey30"}
          >
            {cardData.content && <div className={styles.content}>{cardData.content}</div>}
          </Card>
        </div>
      ))}
    </div>
  )
}

export default CardGallery
